<template>
  <div class="search">
    <form @submit.prevent="search" class="search-form flex flex-row justify-between items-center">
      <input type="text" v-model="searchText" placeholder="Начните вводить название или ключевую фразу">
      <button type="submit">Найти</button>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchText: '',
    }
  },
  methods: {
    search() {
      if (this.searchText.trim()) {
        this.$emit('search', this.searchText);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

.search {
  width: 90%;
  @media (min-width: 1200px) {
    width: 50%;
  }
  @media (max-width: 700px) {
    width: 100%;
  }
}

.search-form {
  border: 2px solid $orange-pink;
  border-radius: 8px;
  height: 50px;
  font-size: 20px;

  @media (max-width: 1000px) {
    height: 40px;
  }

  @media (max-width: 700px) {
    font-size: 16px;
    height: 30px;
    border-radius: 5px;
  }

  @media (max-width: 500px) {
    font-size: 12px;
    height: 30px;
  }

  input[type=text] {
    padding: 0 15px;
    width: 100%;

    @media (max-width: 700px) {
      padding: 0 10px;      
    }

    @media (max-width: 500px) {
      padding: 0 5px;
    }
  }

  input[type=text]:focus {
    outline: none;
  }

  button[type=submit] {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $orange-pink;
    border-radius: 8px;
    width: 120px;
    height: 50px;

    @media (max-width: 1000px) {
      width: 95px;
      height: 40px;
    }

    @media (max-width: 700px) {
      height: 30px;
      width: 70px;
      border-radius: 5px;
    }
  }
}
</style>