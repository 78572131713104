import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import AboutView from '@/views/AboutView.vue'
import CatalogView from '@/views/CatalogView.vue'
import CatalogProductsView from '@/views/CatalogProductsView.vue'
import BlogView from '@/views/BlogView.vue'
import ProductView from '@/views/ProductView.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView,
  },
  {
    path: '/catalog',
    name: 'catalog',
    component: CatalogView,
  },
  {
    path: '/catalog/category/:id/:name',
    name: 'catalog-by-category',
    component: CatalogProductsView,
  },
  {
    path: '/catalog/search/:searchText',
    name: 'catalog-by-search',
    component: CatalogProductsView,
  },
  {
    path: "/blog",
    name: 'blog',
    component: BlogView,
  },
  {
    path: '/products/:article',
    name: 'products',
    component: ProductView,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router;
