<template>
  <section class="about">
    <h1>О нас</h1>
    <div class="about__text">
      <p>
        Мы — садовый центр, специализирующийся на многолетних растениях.
        Наши растения разводятся и выращиваются либо на собственных площадях, либо приобретаются у заслуживших доверие европейских и российских питомников.
        В ассортименте нашего центра вы найдете множество разнообразных сортов гортензий, хвойников и других растений, специально подобранных для нашего климата.
        Для вашего удобства у нас также представлена вся линейка высококачественных грунтов для посадки Агробалт, признанных лучшими в России.
      </p>
      <p>
        Добро пожаловать в наш садовый мир, где каждый сможет найти что-то особенное для своего сада!
      </p>
    </div>
  </section>
</template>


<style lang="scss" scoped>
@import '@/assets/variables.scss';

.about {
  h1 {
    font-size: $header-desktop;
  }
}

.about__text {
  p {
    font-size: $about-desktop;
    text-align: start;

    @media (max-width: 700px) {
      font-size: $about-tablet;
    }

    @media (max-width: 400px) {
      font-size: $about-mobile;
    }
  }
}
</style>

