<template>
  <div class="logo">
    <img v-if="isHeader" class="w-[100px] h-[100px] max-[600px]:w-[50px] max-[600px]:h-[50px]" src="@/assets/images/logo.webp" alt="Logo">
    <img v-else class="w-[100px] h-[100px] max-[600px]:w-[200px] max-[600px]:h-[200px]" src="@/assets/images/logo.webp" alt="Logo">
  </div>
</template>

<script>
export default {
  props: {
    isHeader: Boolean,
  }
}
</script>

<style lang="scss" scoped>
.logo {
  margin: 0 50px;

  @media (max-width: 1000px) {
    margin: 0 25px;
  }

  @media (max-width: 700px) {
    margin: 0 10px;
  }

  img {
    max-width: none;
  }
}
</style>