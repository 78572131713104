<template>
  <footer class="flex flex-col justify-center items-center">
    <div class="w-full flex max-[850px]:flex-col-reverse">
      <iframe class="w-full" src="https://yandex.ru/map-widget/v1/?um=constructor%3A987d36860a9bffc73c741e134c4e6a6eec17194add1f203a882d9485248689d7&amp;source=constructor" width="1340" height="575" frameborder="0"></iframe>
      <div class="min-[851px]:relative">
        <div class="leading-[1.1] contacts-block flex flex-col justify-around items-start text-left">
          <h2 class="font-bold text-[1.5em]">Контакты</h2>
          <p><b>Адрес:</b> Полевая улица, 52, село Ближнее Борисово, Кстовский муниципальный округ, Нижегородская область</p>
          <p><b>Контакты:</b><br><a href="tel:+79202538078"><strong>+7 (920) 253 80 78</strong></a></p>
          <p><b>Режим работы:</b><br>Пн-Вс c 10:00 до 18:00, без выходных и перерывов на обед</p>
          <div class="social-networks">
            <span><b>Социальные сети:</b></span>
            <div class="flex flex-row gap-[10px]">
              <a class="vk block" href="https://vk.com/club226057422" target="_blank">
                <img src="@/assets/images/VK_Logo.svg" width="31" height="31" alt="VK logo">
              </a>
              <a class="avito block" href="https://www.avito.ru/brands/5983faba48714b1541caa72833465e17/all" target="_blank">
                <img src="@/assets/images/avito_Logo.webp" width="31" height="31" alt="Avito logo">
              </a>
              <a class="youtube block" href="https://youtube.com/@rta77557" target="_blank">
                <img src="@/assets/images/Youtube_icon-icons.com_66802.png" width="31" height="31" alt="YouTube logo">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div class="footer__info flex flex-row max-[600px]:flex-col-reverse justify-center items-center">
      <div class="flex flex-col justify-center items-center">
        <logo-item :isHeader="false"></logo-item>
        <h3 class="my-4 max-[600px]:text-[22px]"><strong>flowerpalette.ru</strong></h3>
      </div>
      <div class="footer__info__description">
        <h2 class="font-bold my-8 text-footer-text">Спасибо, что выбираете нас!</h2>
      </div>
    </div>
  </footer>
</template>


<script>
import LogoItem from './LogoItem.vue';

export default {
  components: {
    LogoItem,
  }
}
</script>


<style lang="scss" scoped>
@import '@/assets/variables.scss';

hr {
  height: 3px;
  width: 100%;
  color: #FFE0D1;
  background-color: #FFE0D1;

  @media (max-width: 900px) {
    display: none;
  }
}

footer {
  padding: 50px 50px 0;
  max-width: 1440px;
  box-sizing: border-box;
  width: 100%;
  gap: 40px;

  iframe {
    max-width: 1340px;

    @media (max-width: 700px) {
      height: 400px;
    }
  }

  @media (max-width: 700px) {
    padding: 20px 20px 0;
    gap: 0;
  }

  @media (max-width: 600px) {
    padding: 10px 10px 0;
  }
}

.footer__info {
  width: 100%;
  gap: 200px;

  @media (max-width: 900px) {
    gap: 100px;
  }

  @media (max-width: 750px) {
    gap: 50px;
  }

  @media (max-width: 600px) {
    gap: 0;
  }
}

.contacts-block {
  position: absolute;
  width: 320px;
  height: 380px;
  background-color: $map-info-background;
  padding: 20px;
  right: 800px;
  bottom: 50px;
  z-index: 9;
  box-shadow: 2px 2px 15px -5px $text-dark;

  @media (max-width: 1250px) {
    right: 600px;
  }

  @media (max-width: 1050px) {
    right: 420px;
  }

  @media (max-width: 850px) {
    position: static;
    right: 0;
    width: 100%;
    box-shadow: none;
    padding: 20px 0;
  }

  @media (max-width: 400px) {
    width: 290px;
  }
}

.social-networks {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>