<template>
  <h1
    class="font-bold text-left my-[28px] max-[1000px]:my-[24px] max-[700px]:my-[16px] max-[500px]:my-[10px] text-[46px] max-[1000px]:text-[38px] max-[700px]:text-[28px] max-[500px]:text-[20px]"
  >{{ text }}</h1>
</template>

<script>
export default {
  props: {
    text: String,
  }
}
</script>