<template>
  <CarouselItem></CarouselItem>
  <ProductSample title="Хиты продаж" path="sale-hits"></ProductSample>
  <PopularCategories></PopularCategories>
  <ProductSample title="Эксклюзив" path="only-with-us"></ProductSample>
  <ProductSample title="Декорирование и ландшафт" path="decoration"></ProductSample>
  <ProductSample title="Популярные хвойные" path="popular-conifers"></ProductSample>
  <ProductSample title="Самые вкусные ягоды и плоды" path="delicious-berries-fruits"></ProductSample>
  <ProductSample title="Новинки" path="news"></ProductSample>
  <ProductSample title="Живая изгородь" path="hedge"></ProductSample>
</template>

<script>
import CarouselItem from '@/components/HomePage/CarouselItem'
import ProductSample from '@/components/HomePage/ProductSample'
import PopularCategories from '@/components/HomePage/PopularCategories'

export default {
  components: {
    ProductSample,
    CarouselItem,
    PopularCategories,
  }
}
</script>
