<template>
  <button @click="clickHandler"><span>Очистить✕</span></button>
</template>

<script>
export default {
  methods: {
    clickHandler() {
      this.$emit('clear');
    }
  }
}
</script>

<style lang="scss">
button {
  font-size: 22px;

  span {
    @media (min-width: 900px) {
      :hover {
        text-decoration: underline;
      }
    }
  }
}
</style>