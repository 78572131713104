<template>
  <HeaderItem></HeaderItem>
  <main>
    <RouterView/>
  </main>
  <FooterItem></FooterItem>
</template>

<script>
import HeaderItem from '@/components/Header/HeaderItem.vue'
import FooterItem from '@/components/FooterItem.vue'
import { RouterView } from 'vue-router';

export default {
  components: {
    HeaderItem,
    FooterItem,
    RouterView,
  }
}
</script>

<style lang="scss">
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

main {
  padding: 0 50px;
  max-width: 1440px;
  box-sizing: border-box;
  width: 100%;

  @media (max-width: 600px) {
    padding: 0 10px;
  }
}

h1 {
  @media (max-width: 1000px) {
    font-size: 34px;
  }

  @media (max-width: 700px) {
    font-size: 26px;
  }
}
</style>
