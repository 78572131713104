<template>
  <section class="carousel w-full">
    <div class="carousel__container">
      <img class="w-full rounded-[30px] max-[800px]:rounded-[10px]" src="@/assets/images/banner_dev.webp" alt="Баннер">
    </div>
  </section>
</template>


<style lang="scss" scoped>
</style>