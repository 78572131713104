import { createStore } from 'vuex'

export default createStore({
  state: {
    isAuth: false,
    user: null,
  },
  getters: {
  },
  mutations: {
    switchAuth(state) {
      state.isAuth = true;
    },
    // setUser(state) {

    // }
  },
  actions: {
  },
  modules: {
  }
})
