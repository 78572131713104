<template>
  <button @click="$emit('display', imageUrl)">
    <img
      :class="{ 'object-cover': true, 'bg-cover': true, 'bg-center': true, 'bg-no-repeat': true, 'rounded-[8px]': true, 'active': isActive }"
      :src="imageUrl"
      alt="Image"
    >
  </button>
</template>

<script>
export default {
  props: {
    imageUrl: String,
    isActive: Boolean,
  }
}
</script>

<style lang="scss" scoped>
img {
  width: 75px;
  min-width: 75px;
  height: 75px;
  min-height: 75px;

  @media (max-width: 1000px) {
    width: 55px;
    min-width: 55px;
    height: 55px;
    min-height: 55px;
  }
}

.active {
  border: 3px solid rgb(243, 145, 102);
  box-sizing: content-box;
}
</style>