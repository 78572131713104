<template>
  <router-link :to="{ name: 'catalog-by-category', params: { id: this.id, name: this.name } }"
    class="category-card flex flex-col justify-end items-start rounded-[10px] max-[800px]:rounded-[5px] no-underline bg-cover bg-center bg-no-repeat"
    :style="{ backgroundImage: `url(${image})` }"
  >
    <div class="category-card__name box-content flex flex-row justify-start items-center">
      <h3 class="font-bold text-left text-[1.3vw] max-[900px]:text-[1.8vw] min-[1440px]:text-[19px] lg:hover:underline">{{ name }}</h3>
    </div>
  </router-link>
</template>


<script>
export default {
  props: {
    image: String,
    name: String,
    id: Number,
  },
}
</script>


<style lang="scss" scoped>
@import '@/assets/variables.scss';

.category-card {
  width: 100%;
  aspect-ratio: 1 / 1;
}

.category-card__name {
  background-color: $dark-yellow;
  color: $text-dark;
  width: 80%;
  height: 25%;
  border-radius: 0 0 0 10px;
  padding-left: 3%;

  @media (max-width: 800px) {
    border-radius: 0 0 0 5px;
  }
}
</style>